<template>
  <div class="content">
    <router-view/>
    <BackgroundAudioPlayer/>
    <ImageViewer />
    <HintsViewer />
    <BlockViewer />
    <notifications />
    <ServiceWorkerUpdatePopup />
    <SubscriptionPopup/>
  </div>
</template>

<script setup lang="ts">
import {useHead} from "@vueuse/head";
import BackgroundAudioPlayer from "@/components/UI/AudioPlayer/BackgroundAudioPlayer.vue";
import ImageViewer from "@/components/UI/ImageViewer/ImageViewer.vue";
import HintsViewer from "@/components/Home/Content/HintsViewer.vue";
import BlockViewer from "@/components/Home/Content/BlockViewer.vue";
import {Notifications} from "@kyvg/vue3-notification";
import ServiceWorkerUpdatePopup from "@/pwa/components/ServiceWorkerUpdatePopup.vue";
import SubscriptionPopup from "@/components/User/Settings/Popup/SubscriptionPopup.vue";

useHead({
  title: 'Magic moon'
})
</script>

