<script setup lang="ts">
import {ref} from "vue";

const {min, max, step} = defineProps({
  min: {
    type: Number,
    default: 0,
  },
  max: {
    type: Number,
    default: 100,
  },
});
const emit = defineEmits(['wrapinput']);
const [model] = defineModel()
const inputRef = ref<HTMLInputElement | null>(null);
const onWrapperClick = (e: PointerEvent) => {
  const percent = e.offsetX / e.target.getBoundingClientRect().width;
    emit('wrapinput', percent);
};
const onWrapperMove = (e: TouchEvent) => {
  const wrapper = e.target.getBoundingClientRect() as DOMRect
  const offsetX = Math.max(0,e.touches.item(0).clientX - wrapper.x)
  const maxWidth = wrapper.width
  const percent = Math.min(1, offsetX/maxWidth)
  emit('wrapinput', percent);
}
</script>
<template>
  <div class="audio-slider" @click.self="onWrapperClick" @touchmove.stop="onWrapperMove">
    <input
        ref="inputRef"
        v-model="model"
        :min="min"
        :max="max"
        type="range"
        :style="`--ProgressPercent: ${model}%`"
        @input="$emit('update:modelValue', $event.target.value)"
    />
  </div>
</template>
<style scoped lang="scss">
.audio-slider {
  height: 40px;
  display: flex;
  align-items: center;
  --trackHeight: 4px;
  --thumbRadius: 15px;

  input[type="range"] {
    position: relative;
    appearance: none;
    background: none;
    border-radius: 999px;
    z-index: 0;
    height: 4px;
    width: 100%;

    &::before {
      content: "";
      display: block;
      position: absolute;
      width: var(--ProgressPercent, 100%);
      height: 100%;
      background: linear-gradient(91.59deg, #FFBA35 0%, #FD9F30 100%);
      border-radius: 999px;
    }

    &::-webkit-slider-runnable-track {
      appearance: none;
      background: #EFEFEF;
      height: var(--trackHeight);
      border-radius: 999px;
    }

    &::-webkit-slider-thumb {
      position: relative;
      width: var(--thumbRadius);
      height: var(--thumbRadius);
      margin-top: calc((var(--trackHeight) - var(--thumbRadius)) / 2);
      background: linear-gradient(91.59deg, #FFBA35 0%, #FD9F30 100%);
      border-radius: 999px;
      pointer-events: all;
      appearance: none;
      z-index: 1;
    }

    &::-moz-range-track {
      appearance: none;
      background: #005a3c;
      height: var(--trackHeight);
      border-radius: 999px;
    }

    &::-moz-range-thumb {
      position: relative;
      box-sizing: border-box;
      width: var(--thumbRadius);
      height: var(--thumbRadius);
      margin-top: calc((var(--trackHeight) - var(--thumbRadius)) / 2);
      background: #005a3c;
      border: 1px solid #00865a;
      border-radius: 999px;
      pointer-events: all;
      appearance: none;
      z-index: 1;
    }

    &::-moz-range-thumb {
      position: relative;
      box-sizing: border-box;
      width: var(--thumbRadius);
      height: var(--thumbRadius);
      margin-top: calc((var(--trackHeight) - var(--thumbRadius)) / 2);
      background: #005a3c;
      border: 1px solid #00865a;
      border-radius: 999px;
      pointer-events: all;
      appearance: none;
      z-index: 1;
    }
  }
}

</style>
