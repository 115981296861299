<script setup lang="ts">

import Button from "@/components/UI/Button/Button.vue";
import FullPopup from "@/components/UI/Pupup/FullPopup.vue";
import ProLabelBig from "@/components/UI/Label/ProLabelBig.vue";
const show = defineModel('show')
const onClick = () => {
  show.value = false
}
</script>

<template>
  <FullPopup  v-model:show="show" theme="dark" closed :padding="false"  :max-height="410">
    <div class="subscription-preview">
      <div class="subscription-preview__about">О подписке</div>
    </div>
    <div class="subscription-content">
      <div class="subscription-content__congratulation">
        <div class="subscription-content__congratulation__subtitle">Поздравляем!</div>
        <div class="subscription-content__congratulation__title">Вы теперь <ProLabelBig/></div>
      </div>
      <div class="subscription-content__footer">
        <Button text="Ооочень хорошо" active class="subscription-content__footer__button" @click="onClick"></Button>
      </div>
    </div>
  </FullPopup>
</template>

<style scoped lang="scss">

.subscription-preview {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 34px 20px 20px;
  box-sizing: border-box;
  position: relative;
  &__about {
    font-size: 10px;
    font-weight: 700;
    color: rgba(245, 245, 245, 0.5);
  }
}

.subscription-content {
  font-size: 14px;
  font-weight: 500;
  color: #F5F5F5;

  &__congratulation {
    margin: 10px 0 30px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    background-image: url(@/assets/images/subscription/stars.png);
    background-position: center;
    background-size: cover;
    aspect-ratio: 380/160;
    justify-content: center;
    align-items: center;
    &__subtitle {
      font-size: 16px;
      font-weight: 700;
      color: rgba(245, 245, 245, 0.5);
    }
    &__title {
      align-items: center;
      font-size: 30px;
      font-weight: 700;
      color: #F5F5F5;
      display: flex;
      gap: 10px;
    }
  }
  &__footer{
    padding: 0 20px;
    &__button {
      height: 80px;
    }
  }
}
</style>
