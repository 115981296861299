<script setup lang="ts">
import {computed, shallowRef} from "vue";
const props = defineProps({
  value: String
})
const [model] = defineModel()
const active = computed(() => model.value == props.value);
const onClick = () => {
  model.value = props.value;
}
</script>

<template>
  <div class="radio-button" :class="{active}" @click="onClick"></div>
</template>

<style scoped lang="scss">

.radio-button {
  width: 30px;
  height: 30px;
  display: flex;
  flex-shrink: 0;
  box-sizing: border-box;
  border-radius: 50%;
  position: relative;
  align-items: center;
  justify-content: center;
  border: 1px solid #B7B7B8;
  &:hover{
    cursor: pointer;
  }
  &::after {
    opacity: 0;
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #FD9F30;
  }

  &.active {
    border-color: #FD9F30;
    &::after {
      opacity: 1;
    }
  }
}
</style>
