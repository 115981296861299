import BillingService from "@/ts/Billing/Service/BillingService";
import Subscription from "@/ts/User/Types/Subscription";
import {PaymentResult} from "@/ts/Billing/Types/Widget";

type Event = 'show' | 'hide' | 'subscribed';
type Events = {
    [key in Event]?: (() => void)[];
}
export default class SubscriptionService {

    private successKey: string = 'subscribed';
    private static instance: SubscriptionService;
    private eventListeners: Events = {};
    private static creating: boolean = false;


    private constructor() {
        if (!SubscriptionService.creating) {
            throw new Error("You must use SubscriptionService.getInstance() to create an instance.");
        }

    }

    static getInstance(): SubscriptionService {
        if (!SubscriptionService.instance) {
            SubscriptionService.creating = true;
            SubscriptionService.instance = new SubscriptionService();
            SubscriptionService.creating = false;
        }
        return SubscriptionService.instance;
    }

    public async pay(subscription: Subscription): Promise<void> {
        const result = await BillingService.getInstance().pay(subscription)
        this.onPaymentComplete(result);
    }

    private onPaymentComplete(paymentResult: PaymentResult) {
        if (paymentResult.success) {
            localStorage.setItem(this.successKey, 'true');
            window.location.reload();
        }
    }

    private isSubscribed(): boolean {
        return localStorage.getItem(this.successKey) === 'true';
    }

    public init() {
        if(this.isSubscribed()) {
            this.dispatchEvent('subscribed');
            localStorage.removeItem(this.successKey);
        }
    }

    private dispatchEvent(event: Event) {
        this.eventListeners[event]?.forEach(listener => listener());
    }

    public addEventListener(event: Event, listener: () => void) {
        if (!this.eventListeners[event]) {
            this.eventListeners[event] = [];
        }
        this.eventListeners[event]?.push(listener);
    }

    public showPopup() {
        this.dispatchEvent('show');
    }
}
