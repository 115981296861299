import {Options, PaymentResult, Widget} from "@/ts/Billing/Types/Widget";
import AuthService from "@/ts/User/Service/AuthService";
import UserService from "@/ts/User/Service/UserService";
import Subscription from "@/ts/User/Types/Subscription";

declare module globalThis {
    const cp: {
        CloudPayments: Widget;
    }
}

export default class BillingService {
    private static instance: BillingService;
    private static creating: boolean = false;
    private scriptUrl: string = 'https://widget.cloudpayments.ru/bundles/cloudpayments.js';
    private publicId: string = process.env.VUE_APP_CLOUDPAYMENTS_TOKEN as string;
    private isLoadedVendorScript: boolean = false;
    private widget?: Widget;

    private constructor() {
        if (!BillingService.creating) {
            throw new Error("You must use BillingService.getInstance() to create an instance.");
        }
    }

    static getInstance() {
        if (!BillingService.instance) {
            BillingService.creating = true;
            BillingService.instance = new BillingService();
            BillingService.creating = false;
        }
        return BillingService.instance;
    }

    public async pay(subscription: Subscription): Promise<PaymentResult> {
        await this.load();
        const userId = await AuthService.getUserId();
        return new Promise((resolve, reject) => {
            this.widget!.pay("auth", {
                    publicId: this.publicId,
                    description: subscription.name,
                    amount: subscription.price,
                    invoiceId: subscription.id,
                    currency: "RUB",
                    accountId: userId,
                    skin: "mini",
                    autoClose: 3,
                },
                {
                    onComplete: (paymentResult: PaymentResult) => {
                        if (paymentResult.success) {
                            resolve(paymentResult);
                        } else {
                            reject(new Error('Payment failed'));
                        }
                    }
                })
        });
    }

    private async load(): Promise<void> {
        if (this.isLoadedVendorScript) return;
        return new Promise((resolve, reject) => {
            const script = document.createElement('script');
            script.src = this.scriptUrl;
            script.async = true;
            script.onload = () => {
                if (globalThis.cp) {
                    this.isLoadedVendorScript = true;
                    //@ts-ignore
                    this.widget = new globalThis.cp.CloudPayments();
                    resolve();
                } else {
                    reject(new Error('CloudPayments object not found in window'));
                }
            }
            script.onerror = () => reject(new Error('Failed to load the script'));
            document.head.appendChild(script);
        });
    }
}
