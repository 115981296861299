import {ApiClient} from "@/ts/Utility/ApiClient";
import OAuthUser from "@/ts/User/Types/OAuthUser";
import UserCreateDto from "@/ts/User/Types/UserCreateDto";
import Credentials from "@/ts/User/Types/Credentials";
import JwtToken from "@/ts/User/Types/JwtToken";
import {clearAuthTokens, setAuthTokens} from 'axios-jwt';
import {getAccessToken} from "axios-jwt/src/tokensUtils";
import {jwtDecode} from "jwt-decode";
import router from "@/router";
import VerifyByPhoneResponse from "@/ts/User/Types/VerifyByPhoneResponse";
import UserProfile from "@/ts/User/Types/UserProfile";
import LoginByPhoneResponse from "@/ts/User/Types/LoginByPhoneResponse";

const client = new ApiClient({jwt: false});

interface DecodedToken {
    id: string;
    username: string;
    exp: number;
    iat: number;
    roles: string[];
}

export default class AuthService {
    static async getGoogleUser(accessToken: string): Promise<OAuthUser> {
        return await client.post<OAuthUser>('auth/google/check', {accessToken});
    }

    static async getYandexUser(accessToken: string): Promise<OAuthUser> {
        return await client.post<OAuthUser>('auth/yandex/check', {accessToken});
    }


    static async yandexSuggest(): Promise<string> {
        const config = {
            client_id: process.env.VUE_APP_OAUTH_YANDEX_CLIENT_ID,
            response_type: "token",
            redirect_uri: location.origin + "/oauth/yandex"
        }
        //@ts-ignore
        const auth = window.YaAuthSuggest
        //@ts-ignore
        const {handler} = await auth.init(
            config,
            location.origin,
            {view: "default"}
        )
        const {access_token} = await handler()
        return access_token
    }

    static async setJwtToken(token: JwtToken): Promise<void> {
        return await setAuthTokens({
            accessToken: token.token,
            refreshToken: token.refresh_token
        });
    }

    static async getUserId() {
        const token = await getAccessToken();
        if (!token) {
            throw new Error('Access token is not available');
        }
        return jwtDecode<DecodedToken>(token)["id"];

    }

    static async login(credentials: Credentials): Promise<JwtToken> {
        const token = await client.post<JwtToken>('auth', credentials);
        await this.setJwtToken(token);
        return token;
    }

    static async loginByPhone(phone: string): Promise<LoginByPhoneResponse> {
        return await client.post<LoginByPhoneResponse>(`auth/phone/${phone}`);
    }

    static async verifyByPhone(phone: string, code: string): Promise<VerifyByPhoneResponse> {
        const token = await client.post<VerifyByPhoneResponse>(`auth/phone/${phone}/verify/${code}`);
        await this.setJwtToken(token as JwtToken);
        return token;
    }

    static async logout(): Promise<void> {
        await clearAuthTokens();
        await router.push({name: 'auth'})
    }

    static async register(user: UserCreateDto, avatar: File | null = null, code: string | null = null): Promise<JwtToken> {
        const formData = new FormData();
        formData.append('data', JSON.stringify(user));
        if (code){
            formData.append('code', code);
        }
        if (avatar) {
            formData.append('avatar', avatar);
        }
        const token = await client.post<JwtToken>('v2/auth/registration', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
        });
        await this.setJwtToken(token);
        return token;
    }

    static async resetPasswordRequest(email: string): Promise<void> {
        await client.post('auth/password/request', {email});
    }

    static async checkResetPasswordToken(token: string): Promise<void> {
        await client.get('auth/password/reset/' + token + '/check');
    }

    static async resetPassword(token: string, password: string): Promise<void> {
        await client.post('auth/password/reset/' + token, {password});
    }
}
