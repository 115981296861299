<script setup lang="ts">
import Subscription from "@/ts/User/Types/Subscription";
import YellowRadioButton from "@/components/FormInput/YellowRadioButton.vue";

const props = defineProps<Subscription>()
const [model] = defineModel()
const onClick = () => {
  model.value = props.id;
}
</script>

<template>
  <div class="subscription-item" @click="onClick">
    <div class="subscription-item__left">
      <YellowRadioButton v-model="model" :value="id"/>
    </div>
    <div class="subscription-item__center">
      <div class="subscription-item__center__name">{{ name }}</div>
      <div class="subscription-item__center__price">{{ price.toLocaleString('ru-RU') }} руб.</div>
    </div>
    <div class="subscription-item__right" v-if="discount > 0">-{{ discount }}%</div>
  </div>
</template>

<style scoped lang="scss">
.subscription-item {
  display: flex;
  align-items: center;
  height: 60px;
  padding: 0 15px;
  background-color: #fff;
  border-radius: 20px;
  color: #363739;
  font-size: 14px;
  gap: 20px;
  &__left {

  }

  &__center {

    &__name {
      font-weight: 700;
    }

    &__price {

    }
  }
  &__right{
    margin-left: auto;
    width: 50px;
    height: 30px;
    display: flex;
    background-color: #6C7BB2;
    border-radius: 10px;
    font-weight: 400;
    align-items: center;
    justify-content: center;
    color: #fff;
  }
}
</style>
