import { notify } from "@kyvg/vue3-notification";

export default class DevModeService {
    private isDevMode: boolean = false;
    private checkingCount: number = 0;
    private checkLimit: number = 10;
    private checkTimer: any = null;
    private static instance: DevModeService;
    private static creating: boolean = false;

    private constructor() {
        if (!DevModeService.creating) {
            throw new Error("You must use DevModeService.getInstance() to create an instance.");
        }
    }

    static getInstance() {
        if (!DevModeService.instance) {
            DevModeService.creating = true;
            DevModeService.instance = new DevModeService();
            DevModeService.creating = false;
        }
        return DevModeService.instance;
    }

    public isDev(): boolean {
        return this.isDevMode;
    }

    public check(): void {
        this.checkingCount++;
        if (this.checkTimer) {
            clearTimeout(this.checkTimer);
        }
        this.checkTimer = setTimeout(() => {
            this.checkingCount = 0;
        }, 20000);

        if (this.checkingCount >= this.checkLimit) {
            this.isDevMode = true;
            notify({
                type: "success",
                text: "Dev mode is enabled",
            });
            this.checkingCount = 0;
        }
    }
}
