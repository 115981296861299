<script setup lang="ts">
import BackButton from "@/components/UI/Button/BackButton.vue";
import CloseButton from "@/components/UI/Button/CloseButton.vue";
import Popup from "@/components/UI/Pupup/Popup.vue";

const props = withDefaults(defineProps<{
  title?: string,
  padding?: boolean,
  closed?: boolean,
  theme?: 'light' | 'dark'
}>(), {
  padding: true,
  theme: 'light'
})
const show = defineModel('show')
const onBack = () => {
  show.value = false;
}

</script>

<template>
  <Popup v-model:show="show" :theme="theme" >
    <div class="popup__header absolute" v-if="closed">
      <CloseButton @click="onBack" :theme="theme"/>
    </div>
    <div class="popup__header" v-else>
      <BackButton @click="onBack"/>
      <div class="popup__title">
        {{ title }}
      </div>
    </div>
    <div class="popup__content" :class="{padding}">
      <slot/>
    </div>
    <div class="popup__footer" v-if="$slots.footer">
      <slot name="footer"/>
    </div>
  </Popup>
</template>

<style scoped lang="scss">
.popup {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    box-sizing: border-box;

    &.absolute {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      justify-content: end;
      z-index: 1;
    }
  }


  &__title {
    font-size: 16px;
    font-weight: 700;
    color: #363739;
  }

  &__content {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    box-sizing: border-box;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.48px;
    text-align: left;
    color: #363739;
    &.padding {
      padding: 10px 20px 20px 20px;
    }
  }

  &__footer {
    padding: 20px;
  }
}
</style>
