<script setup lang="ts">

import Button from "@/components/UI/Button/Button.vue";
import BillingService from "@/ts/Billing/Service/BillingService";
import DevModeService from "@/ts/User/Service/DevModeService";
import BlueCheckIcon from "@/components/UI/Icon/BlueCheckIcon.vue";
import {nextTick, onMounted, ref, watch} from "vue";
import Subscription from "@/ts/User/Types/Subscription";
import SubscriptionRepository from "@/ts/User/Repository/SubscriptionRepository";
import FullPopup from "@/components/UI/Pupup/FullPopup.vue";
import SubscriptionItem from "@/components/User/Settings/Popup/SubscriptionItem.vue";
import SubscribedPopup from "@/components/User/Settings/Popup/SubscribedPopup.vue";
import SubscriptionService from "@/ts/User/Service/SubscriptionService";

const show = defineModel('show')
const subscriptions = ref<Subscription[]>([]);
const selectedSubscriptionId = ref<string | null>(null);
const showSubscribedPopup = ref(false)
const onPayClick = async () => {
  if (!DevModeService.getInstance().isDev()) return;
  if(selectedSubscriptionId.value === null) return
  if(!acceptOffer.value){
    offerError.value = true
    return
  }
  const selectedSubscription = subscriptions.value.find(subscription => subscription.id === selectedSubscriptionId.value)
  const subscriptionService = SubscriptionService.getInstance()
  await subscriptionService.pay(selectedSubscription!)
}
watch(() => show.value, async () => {
  if (show.value) {
    subscriptions.value = await SubscriptionRepository.getAll()
    const defaultSubscription = subscriptions.value.find(subscription => subscription.isDefault)
    selectedSubscriptionId.value = defaultSubscription?.id || subscriptions.value[0].id
  }
})
const listItems = [
  'Доступ к закрытому клубу Юрия Мурадян (канал+чат клуба)',
  'Уроки по предмету:',
  '«Метапрограммирование реальности»',
  'Новые медитации месяца уровня PRO',
  '10 гениальных дневников для саморефлексии, работы с целями и желаниями и другими аспектами жизни',
  'Полная версия книги «Игра Атлантов. Алхимия мечты»',
  'Метапрограмирующие манифесты, направленные на решения важных задач',
  'Метафорические карты  ответов из бессознательного',
  'Закрытый чат участников тарифа PRO',
  'Закрытый канал Юрия Мурадяна с мощной информацией о психике и Вселенной']
const acceptOffer = ref(true)
const offerError = ref(false)
const onClickOffer = () => {
  acceptOffer.value = !acceptOffer.value
  offerError.value = false
}
onMounted(() => {
  const subscriptionService = SubscriptionService.getInstance()
  subscriptionService.addEventListener('subscribed', () => {
    show.value = false
    showSubscribedPopup.value = true
  })
  subscriptionService.addEventListener('show', () => {
    show.value = true
  })
  subscriptionService.addEventListener('hide', () => {
    show.value = false
  })
  subscriptionService.init()
})
</script>

<template>
  <FullPopup v-model:show="show" theme="dark" closed :padding="false">
    <div class="subscription-preview">
      <div class="subscription-preview__about">О подписке</div>
      <div class="subscription-preview__name">MAGIC PRO</div>
    </div>
    <div class="subscription-content">
      <div>С подпиской PRO вы получаете полный доступ к уникальным инструментам для личностного роста и гармонизации
        жизни:
      </div>
      <div class="subscription-content__achievement-list">
        <div v-for="item in listItems">
          <div>
            <BlueCheckIcon :size="24"/>
          </div>
          <div>{{ item }}</div>
        </div>
      </div>
      <div class="subscription-content__subscriptions">
        <div class="subscription-content__subscriptions__header">Выберите варианты подписки:</div>
        <div class="subscription-content__subscriptions__items">
          <SubscriptionItem
              v-for="subscription in subscriptions"
              v-bind="subscription"
              v-model="selectedSubscriptionId"/>
        </div>
        <div class="subscription-content__offer" @click="onClickOffer">
          <BlueCheckIcon :size="20" :active="acceptOffer" :error="offerError"/>
          Я соглашаюсь с договором оферты
        </div>
      </div>
    </div>
    <div class="subscription-footer" >
      <Button text="Продолжить" active class="subscription-footer__button" @click="onPayClick"></Button>
    </div>
    <div class="subscription-footer__additional">
      <div class="subscription-footer__additional__cancel">Отменить можно в любое время</div>
      <div class="subscription-footer__additional__help">Нужна помощь?</div>
    </div>
  </FullPopup>
  <SubscribedPopup v-model:show="showSubscribedPopup" />
</template>

<style scoped lang="scss">
.subscription-footer {
  padding: 40px 20px 0;

  &__button {
    height: 80px;
  }

  &__additional {
    text-align: center;
    display: flex;
    flex-direction: column;
    padding: 40px 0;
    gap: 15px;

    &__cancel {
      color: rgba(245, 245, 245, 0.5);
    }

    &__help {
      color: #FD9F30;
    }
  }
}

.subscription-preview {
  background-image: url(@/assets/images/subscription/preview.png);
  background-position: center;
  background-size: cover;
  aspect-ratio: 380/180;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 34px 20px 20px;
  box-sizing: border-box;
  position: relative;

  > * {
    z-index: 1;
  }

  &:before {
    content: "";
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: linear-gradient(180deg, #23252900 0%, #23252980 50%);
  }

  &__about {
    font-size: 10px;
    font-weight: 700;
    color: rgba(245, 245, 245, 0.5);
  }

  &__name {
    font-size: 30px;
    font-weight: 700;
    color: #F5F5F5;
    text-align: center;
  }
}

.subscription-content {
  padding: 30px 20px 10px 20px;
  font-size: 14px;
  font-weight: 500;
  color: #F5F5F5;

  &__achievement-list {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    font-size: 12px;
    color: #F5F5F5;
    font-weight: 400;

    > div {
      display: flex;
      gap: 10px;
      align-items: center;
    }
  }

  &__subscriptions {
    margin-top: 40px;
    border-top: 1px solid rgba(245, 245, 245, 0.1);

    &__header {
      padding: 40px 0;
      font-weight: 700;
      font-size: 16px;
      text-align: center;
    }

    &__items {
      display: flex;
      flex-direction: column;
      gap: 20px;
      padding-bottom: 20px;
    }
  }

  &__offer {
    display: flex;
    align-items: center;
    gap: 12px;
    font-size: 14px;
    color: rgba(245, 245, 245, 0.5);
  }

  &__footer {
    margin-top: 30px;
    border-top: 1px solid rgba(245, 245, 245, 0.1);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 30px;

    &__left {
      font-size: 16px;
      color: rgba(245, 245, 245, 0.5);
      font-weight: 700;
    }

    &__right {
      display: flex;
      align-items: center;
      font-size: 12px;
      font-weight: 700;
      gap: 10px;
      padding: 0 10px;
      height: 40px;
      border-radius: 20px;
      background-color: rgba(255, 255, 255, 0.1);

      &__icon {
        background: linear-gradient(91.59deg, #FFBA35 0%, #FD9F30 100%);
        border-radius: 50%;
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

  }
}
</style>
