<script setup lang="ts">
import {onMounted, ref} from "vue";
import {notify} from "@kyvg/vue3-notification";
import Icon from "@/components/UI/Icon.vue";
const isShowed = ref(false);
const onFoundUpdate = () => {
  isShowed.value = true;
}

const update = () => {
  localStorage.setItem('appUpdated', 'true');
  window.location.reload();
}
onMounted(() => {
  if (localStorage.getItem('appUpdated')) {
    isShowed.value = false;  // Hide notification after reload
    notify({
      type: "success",
      text: "Приложение обновлено",
    });
    localStorage.removeItem('appUpdated');
  }

  document.addEventListener('swUpdated', onFoundUpdate, {once: true});
})
</script>

<template>
  <div class="update-notification animated" v-if="isShowed" @click="update">
    <div class="update-notification__icon">
      <Icon name="fire" :size="26" color="#FFBA35"/>
    </div>
    <div class="update-notification__content">
      <div class="update-notification__content__title">Новая версия приложения</div>
      <div class="update-notification__content_-subtitle">нажмите здесь, чтобы обновить</div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@keyframes slideIn {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}

.update-notification {
  position: fixed;
  border-radius: 20px;
  top: 10px;
  left: 10px;
  width: calc(100% - 20px);
  height: 70px;
  background: linear-gradient(90deg, #FFBA35 0%, #FD9F30 100%);
  padding: 5px;
  box-sizing: border-box;
  display: flex;
  z-index: 1000;
  gap: 15px;
  align-items: center;
  &.animated {
    transition: transform 0.5s;
    animation: slideIn 0.5s;
  }
  &__icon {
    width: 60px;
    height: 60px;
    border-radius: 15px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 5px;
    color: #363739;
    &__title {
      font-size: 16px;
      font-weight: 700;
    }

    &__subtitle {
      font-weight: 500;
      font-size: 14px;
    }
  }

}
</style>
