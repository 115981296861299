import {createApp} from "vue";
import App from "./App.vue";
import "./pwa/registerServiceWorker";
import '@vuepic/vue-datepicker/dist/main.css';
import './assets/global.scss';
import router from "@/router";
import {createHead} from '@vueuse/head'
import VueDatePicker from '@vuepic/vue-datepicker';
import GoogleSignInPlugin from "vue3-google-signin"
import * as Sentry from "@sentry/vue";
import Notifications from '@kyvg/vue3-notification'
import {initYandexMetrika} from 'yandex-metrika-vue3';

const googleOptions: any = {
    clientId: process.env.VUE_APP_OAUTH_GOOGLE_CLIENT_ID,
}
const head = createHead()
const app = createApp(App)
const yaMetrikaOptions: any = {
    id: 99297304,
    router: router,
    webvisor: true,
    env: process.env.NODE_ENV
}
app.use(head)
    .use(router)
    .use(Notifications)
    .use(GoogleSignInPlugin, googleOptions)
    .use(initYandexMetrika, yaMetrikaOptions)
    .component('VueDatePicker', VueDatePicker)
    .mount("#app");

if (process.env.VUE_APP_SENTRY_DSN) {
    Sentry.init({
        app,
        dsn: process.env.VUE_APP_SENTRY_DSN,
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration({
                maskAllText: false,
                blockAllMedia: false,
            }),
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost", "127.0.0.1:8080", /^https:\/\/front\.magicmoon\.pro/],
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
}
