import {nextTick, ref, Ref} from "vue";

export type PlayerAudioType = 'meditations'
export type PlayerMetadata = {
    image: string;
    title: string;
    audio: HTMLAudioElement;
    type?: PlayerAudioType;
    id?: number;
}
export default class BackgroundAudio {

    private static instance: BackgroundAudio;
    private static creating: boolean = false;
    public isPlaying: Ref<boolean> = ref(false);
    public isShowedPopup: Ref<boolean> = ref(false);
    private audio: HTMLAudioElement | null = null;
    public playerMetadata: Ref<PlayerMetadata | null> = ref(null);
    private playlist: PlayerMetadata[] = []

    static getInstance() {
        if (!BackgroundAudio.instance) {
            BackgroundAudio.creating = true;
            BackgroundAudio.instance = new BackgroundAudio();
            BackgroundAudio.creating = false;
        }
        return BackgroundAudio.instance;
    }

    public isTheSameAudio(src: string): boolean {
        return this.getPlayerMetadata()?.audio.src === src;
    }

    public addEventListener(event: string, listener: EventListener) {
        this.audio?.addEventListener(event, listener);
    }

    public removeEventListener(event: string, listener: EventListener) {
        this.audio?.removeEventListener(event, listener);
    }

    public getPlayerMetadata(): PlayerMetadata | null {
        return this.playerMetadata.value;
    }

    private constructor() {
        if (!BackgroundAudio.creating) {
            throw new Error("You must use BackgroundAudio.getInstance() to create an instance.");
        }
    }

    public setAudio(metadata: PlayerMetadata, playlist: PlayerMetadata[] = []): this {
        this.stop();
        this.audio = metadata.audio;
        this.playerMetadata.value = metadata;
        this.playlist = [...playlist];
        this.addPlayNextAudioEventListener()
        return this;
    }

    private addPlayNextAudioEventListener() {
        this.addEventListener('ended', () => {
            setTimeout(() => {
                this.playNext();
            }, 1000)
        })
    }

    public stop() {
        if (this.audio) {
            this.pause();
            this.audio.currentTime = 0;
        }
    }

    public async showPopup() {
        this.isShowedPopup.value = false
        await nextTick()
        this.isShowedPopup.value = true;
    }

    public hidePopup() {
        this.isShowedPopup.value = false;
    }

    private setPlayingState(isPlaying: boolean) {
        this.isPlaying.value = isPlaying;
    }

    public async play() {
        this.setPlayingState(true);
        await this.audio?.play();
    }

    public async playNext() {
        if (this.hasNext()) {
            const currentIndex = this.getCurrentIndex();
            this.setAudio(this.playlist[currentIndex + 1], this.playlist);
            await this.play();
        }
    }

    private hasNext(): boolean {
        const currentIndex = this.getCurrentIndex();
        if (currentIndex !== -1) {
            return currentIndex < this.playlist.length - 1;
        }
        return false;
    }

    private getCurrentIndex(): number {
        let currentIndex = -1;
        this.playlist.find((item, index) => {
            if (item.audio.src === this.audio?.src) {
                currentIndex = index;
                return true;
            }
        })
        return currentIndex;
    }

    public pause() {
        this.setPlayingState(false);
        this.audio?.pause();
    }
}
