import {createRouter, createWebHistory} from 'vue-router'
import {isLoggedIn} from "axios-jwt/src/tokensUtils";
import Firebase from "@/ts/Notification/Firebase";
import CategoryRepository from "@/ts/DayBook/Repository/Category/CategoryRepository";

const routes = [
    {name: 'auth', path: '/', component: () => import('@/pages/User/Auth.vue'), meta: {requiresAuth: false}},
    {name: 'email-auth', path: '/auth/email', component: () => import('@/pages/User/EmailAuth.vue'), meta: {requiresAuth: false}},
    {name: 'registration', path: '/auth/registration', component: () => import('@/pages/User/Registration.vue'), meta: {requiresAuth: false}},
    {name: 'phone-registration', path: '/auth/registration/phone', component: () => import('@/pages/User/Registration.vue'), meta: {requiresAuth: false}},
    {name: 'yandex-check', path: '/oauth/yandex', component: () => import('@/pages/User/YandexOAuthCheck.vue')},
    {
        name: 'email-confirmed',
        path: '/auth/email-confirmed',
        component: () => import('@/pages/User/EmailConfirmed.vue'), meta: {requiresAuth: false}
    },
    {
        name: 'password-restore',
        path: '/password/reset/:token',
        component: () => import('@/pages/User/RestorePassword.vue'), meta: {requiresAuth: false}
    },
    {name: 'welcome', path: '/welcome', component: () => import('@/pages/User/Welcome.vue')},
    {name: 'home', path: '/home', component: () => import('@/pages/Home.vue')},
    {name: 'settings', path: '/settings', component: () => import('@/pages/User/Settings.vue')},
    {
        name: 'daybook-categories',
        path: '/daybook/categories',
        component: () => import('@/pages/DayBook/CategoryList.vue')
    },
    {name: 'daybooks', path: '/daybooks', component: () => import('@/pages/DayBook/DayBookList.vue')},
    {
        name: 'daybook-desires-category',
        path: '/daybooks/desires',
        component: () => import('@/pages/DayBook/Category/DesiresCategory.vue')
    },
    {
        name: 'daybook-books-category',
        path: '/daybooks/books',
        component: () => import('@/pages/DayBook/Category/BooksCategory.vue')
    },
    {
        name: 'daybook-intentions-category',
        path: '/daybooks/intentions',
        component: () => import('@/pages/DayBook/Category/IntentionsCategory.vue')
    },
    {
        name: 'daybook-self_reflection-category',
        path: '/daybooks/self_reflection',
        component: () => import('@/pages/DayBook/Category/SelfReflectionCategory.vue')
    },
    {
        name: 'daybook-self_reflection',
        path: '/daybooks/self_reflection/:id',
        component: () => import('@/pages/DayBook/Note/SelfReflectionNote.vue')
    },
    {
        name: 'daybook-talents-category',
        path: '/daybooks/talents',
        component: () => import('@/pages/DayBook/Category/TalentsCategory.vue')
    },
    {
        name: 'daybook-tracker-category',
        path: '/daybooks/tracker',
        component: () => import('@/pages/DayBook/Category/TrackerCategory.vue')
    },
    {
        name: 'daybook-tracker-category-popup',
        path: '/daybooks/tracker/:id',
        component: () => import('@/pages/DayBook/Category/TrackerCategory.vue')
    },
    {
        name: 'daybook-trips-category',
        path: '/daybooks/trips',
        component: () => import('@/pages/DayBook/Category/TripsCategory.vue')
    },
    {
        name: 'daybook-big_money-category',
        path: '/daybooks/big_money',
        component: () => import('@/pages/DayBook/Category/BigMoneyCategory.vue')
    },
    {
        name: 'daybook-acknowledgments-category',
        path: '/daybooks/acknowledgments',
        component: () => import('@/pages/DayBook/Category/AcknowledgmentsCategory.vue')
    },
    {
        name: 'daybook-achievement-category',
        path: '/daybooks/achievement',
        component: () => import('@/pages/DayBook/Category/AchievementCategory.vue')
    },
    {
        name: 'daybook-custom-category',
        path: '/daybooks/custom/category/:id',
        component: () => import('@/pages/DayBook/Category/CustomCategory.vue')
    },
    {name: 'not-found', path: '/:pathMatch(.*)*', component: () => import('@/pages/NotFound.vue')},
    {name: 'goals', path: '/goals', component: () => import('@/pages/Goal/GoalList.vue')},
    {name: 'universe', path: '/universe', component: () => import('@/pages/Universe/Universe.vue')},
    {name: 'books', path: '/universe/books', component: () => import('@/pages/Universe/BookList.vue')},
    {name: 'book', path: '/universe/books/:id', component: () => import('@/pages/Universe/Book.vue')},
    {name: 'chat', path: '/chat', component: () => import('@/pages/Chat/MoonChat.vue')},
    {name: 'meditations', path: '/universe/meditations', component: () => import('@/pages/Universe/Meditation.vue')},
    {name: 'trainings', path: '/universe/trainings', component: () => import('@/pages/Universe/TrainingList.vue')},
    {name: 'training', path: '/universe/trainings/:id', component: () => import('@/pages/Universe/Training.vue')},
    {
        name: 'training-day',
        path: '/universe/trainings/:id/:day',
        component: () => import('@/pages/Universe/Training.vue')
    },
    {name: 'tasks', path: '/universe/trainings/:id/tasks', component: () => import('@/pages/Universe/TaskList.vue')},
    {name: 'musics', path: '/universe/musics', component: () => import('@/pages/Universe/Music.vue')},
    {
        name: 'fortune-telling',
        path: '/universe/fortune-telling',
        component: () => import('@/pages/Universe/FortuneTelling.vue')
    },
    {
        name: 'card-deck',
        path: '/universe/fortune-telling/card-deck/:id',
        component: () => import('@/pages/Universe/CardDeck.vue')
    },
    {name: 'videos', path: '/universe/videos', component: () => import('@/pages/Universe/Video.vue')},
]

const router = createRouter({
    history: createWebHistory(),
    routes,
})
router.beforeEach(async (to, from, next) => {
    const requiresAuth = to.meta.requiresAuth === undefined ? true : to.meta.requiresAuth;
    if(to.name === 'daybooks' && !await CategoryRepository.hasNotEmptiedOrChecked()){
        next({name: 'daybook-categories'});
    }
    if(requiresAuth && window.location.protocol === 'https:'){
        Firebase.getInstance().init();
    }
    if (requiresAuth && !await isLoggedIn() ) {
        next({name: 'auth'})
    } else {
        next()
    }
})
export default router
