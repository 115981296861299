<script setup lang="ts">

</script>

<template>
  <div class="pro-label">PRO</div>
</template>

<style scoped lang="scss">
.pro-label {
  color: #363739;
  width: 88px;
  height: 28px;
  font-size: 12px;
  font-weight: 700;
  border-radius: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(91.59deg, #FFBA35 0%, #FD9F30 100%);
}

</style>
